import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "CE Certification",
  "description": "The CE (Conformité Européenne) certification is a required marking for products to be in the EU market. ",
  "author": "Happy Fei",
  "categories": ["certification"],
  "date": null,
  "featured": false,
  "tags": ["certification"],
  "relatedArticles": []
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In the EU market, the "CE" or "`}<em parentName="p">{`Conformité Européenne"`}</em>{` is a compulsory certification. To allow a product to circulate freely in the EU market, the "CE" mark must be affixed to it to indicate that the product complies with the basic requirements of the European Union's `}<em parentName="p">{`"New Approach to Technical Coordination and Standardization"`}</em>{` directive. This is a mandatory requirement imposed by EU law on products. No matter whether a product is made within the EU or imported, the same law applies to these products on market.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "267px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.27027027027026%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAelnQKQf/8QAGhABAAMAAwAAAAAAAAAAAAAAAwABAhESI//aAAgBAQABBQLnSMqXbzReiF21P//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABwQAAIBBQEAAAAAAAAAAAAAAAECEgAQE0FRYf/aAAgBAQAGPwJlDRVObrFIoOgWmjlTv2pqxRu2/8QAGxABAQACAwEAAAAAAAAAAAAAAREAEDFBYZH/2gAIAQEAAT8hVxmHsufmGBMFrVevNO1HCCgxBGSQWnpr/9oADAMBAAIAAwAAABAwD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQEAAgIDAAAAAAAAAAAAAAERAEExYSFRcf/aAAgBAQABPxBNekBeclGAn3BPhgPIVEHbgQC2bcgg0IFxR3q5VUSIWsTw9YUAWvvP/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/e92ded275069d589d4cfb07252b16629/652f5/ce.webp 259w", "/static/e92ded275069d589d4cfb07252b16629/9156e/ce.webp 267w"],
            "sizes": "(max-width: 267px) 100vw, 267px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/e92ded275069d589d4cfb07252b16629/8356d/ce.jpg 259w", "/static/e92ded275069d589d4cfb07252b16629/05876/ce.jpg 267w"],
            "sizes": "(max-width: 267px) 100vw, 267px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/e92ded275069d589d4cfb07252b16629/05876/ce.jpg",
            "alt": "CE-mark",
            "title": "CE-mark",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`CE certification means that the `}<strong parentName="p">{`product has met the safety requirements stipulated by the EU directive`}</strong>{`. The mark itself shows the commitment of a company to its customers, which establishes the consumer's trust in the product. Hence, products with the CE mark will reduce the risk of sales in the European market.`}</p>
    <p>{`These sales risks for unmarked products include:`}</p>
    <ul>
      <li parentName="ul">{`The risk of being detained and investigated by customs.`}</li>
      <li parentName="ul">{`The risk of being investigated and dealt with by the market supervision agency.`}</li>
      <li parentName="ul">{`Risk of being accused by peers for competitive purposes.`}</li>
    </ul>
    <p>{`CE certification is limited to the `}<strong parentName="p">{`basic safety requirements that the product does not endanger the safety of humans, animals, and goods`}</strong>{`, rather than general quality requirements. The harmonized directives only stipulate the main requirements, and the general directive requirements are standard tasks. Therefore, the precise meaning is: the CE mark is a `}<strong parentName="p">{`safety conformity mark rather than a quality conformity mark`}</strong>{`, and it is the "main requirement" that constitutes the core of the European directive.`}</p>
    <h2 {...{
      "id": "cycle-and-validity-period"
    }}>{`Cycle and Validity Period`}</h2>
    <p>{`Typically, the CE certification process takes about `}<strong parentName="p">{`10 working days from the submission of materials.`}</strong></p>
    <p>{`The `}<strong parentName="p">{`electronic certificate`}</strong>{` will be made publicly available on the organization's official website `}<strong parentName="p">{`within 3 working days of issuance`}</strong>{`.`}</p>
    <p>{`The cost of CE certification can range from several thousand to tens of thousands of dollars, depending on the technical parameters of the product. The cost is mainly based on the relevant directives and applicable standards. For example, the CE certification cost for a consumer electronic is typically lower than that for most medical devices. CE certification is based on the risk associated with the product, and the relevant testing and certification are carried out accordingly.`}</p>
    <p>{`The validity of the CE certification also depends on the risk of the product, relevant standards and guidelines, and any significant changes in production processes or raw materials. The CE certificate is typically `}<strong parentName="p">{`valid until the product is changed or updated in a way that affects its compliance with the relevant legislation`}</strong>{`. For products with relatively `}<em parentName="p">{`high risk`}</em>{`, `}<strong parentName="p">{`follow-up assessments (FPC)`}</strong>{` are conducted annually to maintain the validity of the certificate.`}</p>
    <h2 {...{
      "id": "stages-of-ce-certification"
    }}>{`Stages of CE Certification`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`CTI (Certificate of Technical Information)`}</strong>{`: The CTI is a document that provides detailed technical information about a product that is subject to CE marking. The CTI is used by the `}<em parentName="p">{`Notified Body`}</em>{` to assess the product's compliance with the relevant requirements of the applicable legislation. It is first generated by a third-party company and is also supported by the testing technical report data, such as the TCF.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`EC (Examination Certificate) Attestation of Conformity`}</strong>{`: The CE Certificate of EU Standards, which is issued by the `}<em parentName="p">{`Notified Body (NB)`}</em>{`. According to EU regulations, only NB is qualified to issue EC Type CE declaration.`}</p>
        <p parentName="li">{`The CE Examination Certificate is issued after the Notified Body has reviewed the technical documentation, performed tests on the product, and determined that it complies with the relevant health, safety, and environmental protection requirements of the applicable European legislation.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The CE certificate, also known as the `}<strong parentName="p">{`Declaration of Conformity/Declaration of Compliance`}</strong>{`, is a statement made by the manufacturer or importer that their product meets the required regulations and standards. If the company chooses to make this declaration itself, it can do so in the EU format without the involvement of a third-party agency.`}</p>
        <p parentName="li">{`However, if the enterprise opts to have the certificate issued by a Notified Body of the European Union (NB), it would not need to make a self-declaration. The NB is responsible for ensuring the product's conformity and its certificate is protected by law. The validity of the CE certificate issued by the NB is not in question.`}</p>
        <p parentName="li">{`On the other hand, if the CE certificate is issued by a third-party organization, it is the responsibility of the manufacturer or purchaser to verify its validity. This can be done by evaluating the accuracy and integrity of the data, as well as the technical capability and reputation of the third-party organization. Laboratories that have been accredited by the European Laboratory Accreditation Agency or the National Committee of the Chinese Laboratory (CNAL) according to ISO/IEC17025 are generally considered the most reliable and may even be required by the customer.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "types-of-ce-certification-standards"
    }}>{`Types of CE Certification Standards`}</h2>
    <p>{`Different types of products must comply with different product standards and directives. The following are conventional CE certification directives.`}</p>
    <ul>
      <li parentName="ul">{`Low Voltage Directive CE Certification`}</li>
      <li parentName="ul">{`Electromagnetic compatibility CE certification`}</li>
      <li parentName="ul">{`Machinery Directive CE certification`}</li>
      <li parentName="ul">{`Building Materials Directive CE Certification`}</li>
      <li parentName="ul">{`Pressure Vessel Directive CE Certification`}</li>
      <li parentName="ul">{`Personal Protection Directive CE Certification`}</li>
      <li parentName="ul">{`Medical device CE certification`}</li>
      <li parentName="ul">{`Explosion-proof directive CE certification`}</li>
      <li parentName="ul">{`Radio and communication CE certification`}</li>
      <li parentName="ul">{`Recreational yacht directive CE certification`}</li>
      <li parentName="ul">{`Elevator Directive CE Certification`}</li>
    </ul>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://knowledgebase.nexpcb.com/articles/emc-and-emi-compliance"
      }}><strong parentName="a">{`electromagnetic compatibility (EMC)`}</strong></a>{` directives are a routine test item for `}<strong parentName="p">{`IoT (Internet of Things)`}</strong>{` products related to NexPCB and its partners. However, the specific standard number to be followed depends on the application scenario. For instance, multimedia products can adhere to the standards `}<strong parentName="p">{`EN 55032+A1`}</strong>{` and `}<strong parentName="p">{`EN 55035+A11,`}</strong>{` while general-purpose products can follow the standards E`}<strong parentName="p">{`N 61000-6-1`}</strong>{` and `}<strong parentName="p">{`EN 61000-6-3.`}</strong>{` The appropriate test standard must be determined based on the specific situation.`}</p>
    <h2 {...{
      "id": "documents-required-for-ce-applications"
    }}>{`Documents Required for CE Applications`}</h2>
    <p>{`The exact documents required for the CE certification process will vary depending on the specific product and the applicable European legislation. However, some common documents that may be required include:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Technical documentation`}</strong>{`: Detailed technical information about the product, including its design, construction, and performance.`}</li>
      <li parentName="ol"><strong parentName="li">{`Test reports`}</strong>{`: Results from laboratory testing or other assessments that demonstrate that the product meets the relevant safety, health, and environmental protection requirements.`}</li>
      <li parentName="ol"><strong parentName="li">{`Declarations of Conformity`}</strong>{`: A statement signed by the manufacturer or importer declaring that the product meets the relevant requirements of the applicable legislation.`}</li>
      <li parentName="ol"><strong parentName="li">{`User manuals`}</strong>{`: Information that helps users operate the product safely and effectively.`}</li>
      <li parentName="ol"><strong parentName="li">{`Safety assessments`}</strong>{`: An analysis of the potential risks associated with the product and a demonstration that these risks have been adequately addressed.`}</li>
      <li parentName="ol"><strong parentName="li">{`Quality management system documentation`}</strong>{`: Information about the manufacturer's quality management system, including procedures for design, production, and testing.`}</li>
      <li parentName="ol"><strong parentName="li">{`Product labeling and marking`}</strong>{`: Information about the product that must be displayed on the product or its packagings, such as the CE mark and the manufacturer's name and address.`}</li>
    </ol>
    <p>{`It is important to note that the specific documentation requirements will vary depending on the product and the relevant legislation. Companies should consult the relevant legislation and guidelines and seek advice from a Notified Body to ensure that they have all the required documentation for the CE certification process.`}</p>
    <h2 {...{
      "id": "how-to-get-your-ce-certification"
    }}>{`How to Get Your CE Certification?`}</h2>
    <p>{`The CE certification process typically involves the following stages:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Product assessment`}</strong>{`: The first step in the CE certification process is to assess the product to determine the relevant European health, safety, and environmental protection legislation that applies to it. This includes identifying the product category and the specific directives and standards that apply.`}</li>
      <li parentName="ol"><strong parentName="li">{`Technical Documentation`}</strong>{`: The next step is to prepare a technical file that provides evidence of the product's compliance with the relevant legislation. This file should include a detailed description of the product, the results of any testing or assessments, and any relevant calculations or design documents.`}</li>
      <li parentName="ol"><strong parentName="li">{`Notified Body (NB) assessment`}</strong>{`: The technical file is then submitted to a Notified Body, which is an independent third-party organization that is recognized by the European Commission. The NB will assess the technical file to determine whether the product complies with the relevant legislation.`}</li>
      <li parentName="ol"><strong parentName="li">{`Testing and certification`}</strong>{`: If the Notified Body determines that the product complies with the relevant legislation, it will issue a certificate of conformity. This certificate is required in order to affix the CE marking to the product. In some cases, the Notified Body may require that the product undergo additional testing or assessment before issuing the certificate of conformity.`}</li>
      <li parentName="ol"><strong parentName="li">{`Affixing the CE marking`}</strong>{`: Once the certificate of conformity has been issued, the CE marking can be affixed to the product. The CE marking must be accompanied by the Notified Body number and the manufacturer's identification number.`}</li>
      <li parentName="ol"><strong parentName="li">{`Market Surveillance`}</strong>{`: After the product has been CE certified, it is subject to market surveillance by the authorities in the EEA countries. This includes regular inspections to ensure that the product continues to comply with the relevant legislation.`}</li>
    </ol>
    <p>{`It is important to note that the specific steps and requirements for CE certification will vary depending on the type of product and the relevant legislation that applies. Companies should consult the relevant legislation and guidelines and seek advice from a Notified Body to ensure that they follow the correct CE certification process.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      